export default function IconbProfile() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                d="M24.0604 11.0213C24.0604 6.62446 20.436 3 16.0391 3C11.6422 3 8.01774 6.62446 8.01774 11.0213C8.01774 14.2893 9.97852 17.0819 12.7711 18.3297C9.08726 19.4586 6.11639 22.6077 5.10629 26.5887C5.04687 26.9452 5.22513 27.2423 5.58163 27.3017H5.70046C5.99755 27.3017 6.23522 27.1234 6.29464 26.8264C7.42357 22.2512 11.4045 18.9833 15.8608 18.9833H16.0391C20.436 19.0427 24.0604 15.4182 24.0604 11.0213ZM9.20609 11.0213C9.20609 7.27805 12.2958 4.18835 16.0391 4.18835C19.7824 4.18835 22.8721 7.27805 22.8721 11.0213C22.8721 14.7646 19.7824 17.8543 16.0391 17.8543C12.2958 17.8543 9.20609 14.7646 9.20609 11.0213ZM25.843 19.5774H19.307C18.2969 19.5774 17.5245 20.3499 17.5245 21.36V26.1134C17.5245 27.1235 18.2969 27.8959 19.307 27.8959H25.843C26.8531 27.8959 27.6255 27.1235 27.6255 26.1134V21.36C27.6255 20.4093 26.7936 19.5774 25.843 19.5774ZM26.4371 26.1134C26.4371 26.4699 26.1995 26.7075 25.843 26.7075H19.307C18.9505 26.7075 18.7129 26.4699 18.7129 26.1134V21.36C18.7129 21.0035 18.9505 20.7658 19.307 20.7658H25.843C26.1995 20.7658 26.4371 21.0035 26.4371 21.36V26.1134Z"
                fill="black"
                stroke="black"
                strokeWidth="0.160001"
            />
            <path
                d="M20.5548 23.0831H21.6837C22.0402 23.0831 22.2779 22.8455 22.2779 22.4889C22.2779 22.1324 22.0402 21.8948 21.6837 21.8948H20.5548C20.1983 21.8948 19.9606 22.1324 19.9606 22.4889C19.9606 22.8455 20.1983 23.0831 20.5548 23.0831ZM24.6546 24.3309H20.5548C20.1983 24.3309 19.9606 24.5686 19.9606 24.9251C19.9606 25.2816 20.1983 25.5192 20.5548 25.5192H24.6546C25.0111 25.5192 25.2488 25.2816 25.2488 24.9251C25.2488 24.5686 24.9517 24.3309 24.6546 24.3309Z"
                fill="black"
            />
        </svg>
    );
}
